import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link to navigate to /pricing
import { handleAPIRequest } from '../../../api/api';
const StepRole = ({ nextStep, prevStep, updateRole }) => {
  const [selectedRole, setSelectedRole] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const handleRoleSelection = (role) => {
    fetchBanks();
    setSelectedRole(role);
    updateRole(role); // Update the role in parent state
  };
  const fetchBanks = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        `get-bank-detailsA`,
        null,
        null,
        null
      );
      if (response?.data) {
        setBankDetails(response.data);
        setShowModal(true);
      } else {
        console.log("Failed to fetch bank details.");
      }
    } catch (error) {
      console.error("An error occurred while fetching bank details.");
    }
  };

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Select Your Role</h3>
      
      <div className="flex flex-col sm:flex-row justify-between gap-4 mb-4">
        {/* Supplier */}
        <div
          onClick={() => handleRoleSelection('supplier')}
          className={`p-4 rounded w-full cursor-pointer text-center ${selectedRole === 'supplier' ? 'bg-gray-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'}`}
        >
          <h4 className="font-bold">Supplier</h4>
          <p className="text-sm">1 week free | 1 Month 1000PKR | 6 Months  5000PKR |  1 Year 8000PKR</p>
          <p className="text-sm font-semibold">{selectedRole === 'supplier' ? 'Selected' : ''}</p>
        </div>

        {/* NGO */}
        <div
          onClick={() => handleRoleSelection('company')}
          className={`p-4 rounded w-full cursor-pointer text-center ${selectedRole === 'company' ? 'bg-gray-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'}`}
        >
          <h4 className="font-bold">NGO</h4>
          <p className="text-sm">1 Month 150 Euro | 3 Month 300 Euro | 6 Month 500 Euro | 800 Euro Annual </p>
          <p className="text-sm font-semibold">{selectedRole === 'company' ? 'Selected' : ''}</p>
        </div>

        {/* Donor */}
        <div
          onClick={() => handleRoleSelection('donor')}
          className={`p-4 rounded w-full cursor-pointer text-center ${selectedRole === 'donor' ? 'bg-gray-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-black'}`}
        >
          <h4 className="font-bold">Donor</h4>
          <p className="text-sm">1500 Euro Annually</p>
          <p className="text-sm font-semibold">{selectedRole === 'donor' ? 'Selected' : ''}</p>
        </div>
      </div>

      {/* Link to pricing */}
      <div className="text-center mb-4">
        <Link to="/pricing" className="text-blue-500 hover:underline">
          View Detailed Price Plan
        </Link>
      </div>

      <div className="flex justify-between">
        <button onClick={prevStep} className="bg-green-500 text-white px-4 py-2 rounded">
          Back
        </button>
        <button
          onClick={nextStep}
          className={`bg-green-500 text-white px-4 py-2 rounded ${!selectedRole ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!selectedRole} // Disable the button if no role is selected
        >
          Next
        </button>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full mx-4 overflow-hidden">
            <div className="p-6 overflow-y-auto max-h-[90vh]">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                Bank Details
              </h2>
              <div className="mb-4 text-gray-700 space-y-2">
                <p>
                  <strong>Registration Fee for Supplier:</strong> 1 week free | 1 Month 1000PKR |  6 Months 5000
                  PKR | 1 Year 8000 PKR
                </p>
                <p>
                  <strong>Registration Fee for NGO:</strong> 1 Month 150 Euro | 3
                  Month 300 Euro | 6 Month 500 Euro | 800 Euro Annual
                </p>
                <p>
                  <strong>Registration Fee for Donor:</strong> 1500 Euro Annual
                </p>
              </div>
              <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      Bank Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      Account Title
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      Account Number
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      IBAN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bankDetails.length > 0 ? (
                    bankDetails.map((detail, index) => (
                      <tr key={index} className="border-b">
                        <td className="py-3 px-6 text-gray-600">
                          {detail.bank_name}
                        </td>
                        <td className="py-3 px-6 text-gray-600">
                          {detail.account_title}
                        </td>
                        <td className="py-3 px-6 text-gray-600">
                          {detail.account_number}
                        </td>
                        <td className="py-3 px-6 text-gray-600">
                          {detail.iban}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        className="py-3 px-6 text-center text-gray-500"
                      >
                        No bank details available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="p-4 border-t text-right">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepRole;
