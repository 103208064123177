import React, { useState, useEffect } from "react";
import Navbar from "../Navbar"; // Adjust the import path
import Sidebar from "./superAdminSidebar"; // Adjust the import path
import Footer from "../Footer"; // Adjust the import path
import { handleAPIRequest } from "../../../api/api"; // Adjust the import path
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify"; // Ensure you have react-toastify installed and imported
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast notifications

const GuideVideos = () => {
  const [guides, setGuides] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await handleAPIRequest(
          "get",
          "show-guides",
          null,
          null,
          null
        );
        setGuides(response.guides || []);
      } catch (error) {
        console.error("Error fetching guides:", error);
        toast.error("Failed to fetch guides.");
      }
    };

    fetchGuides();
  }, [token]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleShowModal = (data = null) => {
    setCurrentData(data);
    setIsEdit(!!data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentData(null);
    setShowModal(false);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("title", currentData?.title);
    formData.append("description", currentData?.description);
    formData.append("videoId", currentData?.videoId);

    try {
      const response = await handleAPIRequest(
        "post",
        isEdit ? `edit-guide/${currentData.id}` : "add-guide",
        formData,
        null,
        token
      );
      toast.success(
        isEdit ? "Guide updated successfully!" : "Guide added successfully!"
      );
      if (isEdit) {
        setGuides((prev) =>
          Array.isArray(prev)
            ? prev.map((item) =>
                item.id === currentData.id ? response.data : item
              )
            : [response.data]
        );
      } else {
        setGuides((prev) =>
          Array.isArray(prev) ? [...prev, response.data] : [response.data]
        );
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error saving guide:", error);
      toast.error("Failed to save guide.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await handleAPIRequest("post", `delete-guide/${id}`, null, null, token);
      toast.success("Guide deleted successfully!");
      setGuides((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting guide:", error);
      toast.error("Failed to delete guide.");
    }
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="flex-grow p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-700">Guide Videos</h2>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              onClick={() => handleShowModal()}
            >
              Add Guide Video
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Title
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Description
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                    Video
                  </th>
                  <th className="py-3 px-6 text-center text-sm font-medium text-gray-700">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {guides.length > 0 ? (
                  guides.map((guide) => (
                    <tr key={guide.id} className="border-b">
                      <td className="py-3 px-6 text-gray-600">{guide.title}</td>
                      <td className="py-3 px-6 text-gray-600">
                        {guide.description.slice(0, 30)}...
                      </td>
                      <td className="py-3 px-6 text-gray-600">
                        <button
                          className="text-blue-500"
                          onClick={() => window.open(`https://www.youtube.com/watch?v=${guide.videoId}`, "_blank")}
                        >
                          Watch Video
                        </button>
                      </td>
                      <td className="py-3 px-6 text-center">
                        <button
                          className="bg-yellow-500 text-white px-3 py-1 rounded mr-2 hover:bg-yellow-600"
                          onClick={() => handleShowModal(guide)}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                          onClick={() => handleDelete(guide.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="py-3 px-6 text-center text-gray-500">
                      No guides available at the moment.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </main>
        <ToastContainer />
        <Footer />
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-11/12 max-w-lg p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold mb-4">
              {isEdit ? "Edit Guide Video" : "Add Guide Video"}
            </h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.title || ""}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      title: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.description || ""}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  YouTube Video ID
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={currentData?.videoId || ""}
                  onChange={(e) =>
                    setCurrentData({
                      ...currentData,
                      videoId: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuideVideos;
