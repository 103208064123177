import React from "react";
import { Link } from "react-router-dom";
import blogo from "../../../assets/images/bilcono.jpg"
const Footer = () => {
  return (
    <footer className="bg-blue-600 p-4 text-white">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        {/* Navbar Links */}
        <div className="mb-4 md:mb-0">
          <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
            <li>
              <Link to="/" className="hover:text-gray-400">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about-us" className="hover:text-gray-400">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="hover:text-gray-400">
                Pricing
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="flex flex-col md:flex-row items-center md:space-x-6 text-sm">
          <p>
            For Sales: <strong>sales@ngostenders.com</strong>
          </p>
          <p>
            For Support: <strong>support@ngostenders.com</strong>
          </p>
          <p>
            24/7 Support: <strong>+92 312 1111 002</strong>
          </p>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-4 border-t border-gray-700 pt-2 text-center">

          {/* Logo */}
          {/* <img
            src={blogo}
            alt="Bilcono Logo"
            className="w-20 h-auto mb-2"
          /> */}
          {/* Copyright */}
          <p>&copy; 2024 Bilcono. All rights reserved.</p>
        </div>
   
    </footer>
  );
};

export default Footer;
