import React, { useState, useEffect } from "react";
import { handleAPIRequest } from "../../../api/api"; // Adjust the import path

const StepDocuments = ({
  nextStep,
  prevStep,
  updateDocuments,
  existingDocument,
  updateTag,
  updateAmount,
}) => {
  const [document, setDocument] = useState(existingDocument || null);
  const [tag, setTag] = useState("");
  const [amount, setAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setDocument(selectedFile);
      updateDocuments([selectedFile]);
    }
  };

  const handleRemoveDocument = () => {
    setDocument(null);
    setTag("");
    updateDocuments([]);
    updateTag("");
  };

  useEffect(() => {
    if (existingDocument) {
      setDocument(existingDocument);
    }
  }, [existingDocument]);

  const handleTagChange = (e) => {
    const newTag = e.target.value;
    setTag(newTag);
    updateTag(newTag);
  };

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    updateAmount(newAmount);
  };

  const fetchBanks = async () => {
    try {
      const response = await handleAPIRequest(
        "get",
        `get-bank-detailsA`,
        null,
        null,
        null
      );
      if (response?.data) {
        setBankDetails(response.data);
        setShowModal(true);
      } else {
        console.log("Failed to fetch bank details.");
      }
    } catch (error) {
      console.error("An error occurred while fetching bank details.");
    }
  };

  return (
    <div className="bg-white p-6 rounded shadow-md">
      <h3 className="text-2xl font-semibold mb-4">Payment Documents</h3>
      {!document ? (
        <input
          type="file"
          accept=".png, .jpg, .jpeg, .pdf"
          onChange={handleFileChange}
          className="mb-4 p-2 border rounded w-full"
          required
        />
      ) : (
        <div className="mb-4">
          <label className="block mb-1">Uploaded Document: {document.name}</label>
          <input
            type="text"
            value={tag}
            onChange={handleTagChange}
            className="p-2 border rounded w-full mb-2"
            placeholder="Add a tag for this document"
          />
          <input
            type="text"
            value={amount}
            onChange={handleAmountChange}
            className="p-2 border rounded w-full mb-2"
            placeholder="Add amount of the payment you completed"
          />
          <div className="mt-2">
            <a
              href={URL.createObjectURL(document)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              View Uploaded Document
            </a>
          </div>
          <button
            onClick={handleRemoveDocument}
            className="mt-2 text-red-500 hover:underline"
          >
            Remove Document
          </button>
        </div>
      )}

      <p
        className="text-blue-600 underline cursor-pointer mb-4"
        onClick={fetchBanks}
      >
        Click here for bank account details
      </p>

      <div className="flex justify-between mt-4">
        <button
          onClick={prevStep}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Back
        </button>
        <button
          onClick={nextStep}
          className="bg-green-500 text-white px-4 py-2 rounded"
          disabled={!document}
        >
          Next
        </button>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full mx-4 overflow-hidden">
            <div className="p-6 overflow-y-auto max-h-[90vh]">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                Bank Details
              </h2>
              <div className="mb-4 text-gray-700 space-y-2">
                <p>
                  <strong>Registration Fee for Supplier:</strong> 1 week free | 1 Month 1000PKR |  6 Months 5000
                  PKR | 1 Year 8000 PKR
                </p>
                <p>
                  <strong>Registration Fee for NGO:</strong> 1 Month 150 Euro | 3
                  Month 300 Euro | 6 Month 500 Euro | 800 Euro Annual
                </p>
                <p>
                  <strong>Registration Fee for Donor:</strong> 1500 Euro Annual
                </p>
              </div>
              <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      Bank Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      Account Title
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      Account Number
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">
                      IBAN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bankDetails.length > 0 ? (
                    bankDetails.map((detail, index) => (
                      <tr key={index} className="border-b">
                        <td className="py-3 px-6 text-gray-600">
                          {detail.bank_name}
                        </td>
                        <td className="py-3 px-6 text-gray-600">
                          {detail.account_title}
                        </td>
                        <td className="py-3 px-6 text-gray-600">
                          {detail.account_number}
                        </td>
                        <td className="py-3 px-6 text-gray-600">
                          {detail.iban}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="4"
                        className="py-3 px-6 text-center text-gray-500"
                      >
                        No bank details available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="p-4 border-t text-right">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepDocuments;
